import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import logo from './wordpress.png';

import './Footer.css';

function Footer() {
	return (
			<Row className="Footer">
				<Col xs="12">
					<h6>Copyright © 2020 Sourcery Software. All rights reserved.</h6>
				</Col>
			</Row>
	);
}

export default Footer;