import React from 'react';
import { Container } from 'reactstrap';

import Panel1 from './Panel1.js';
import Panel2 from './Panel2.js';
import Panel3 from './Panel3.js';
 
const Home = () => {
    return (
       <div>
			<Container>
				<Panel1 />
				<Panel2 />
				<Panel3 />
			</Container>
       </div>
    );
}
 
export default Home;