import React from 'react';
 
const Contact = () => {
    return (
       <div>
          <h1>Contact US</h1>
          <p>Contact US page body content</p>
       </div>
    );
}
 
export default Contact;