import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import logo from './webdev.jpg';

import './Panel.css';

function Panel1() {
	return (
		<Container>
			<Row className="Panel">
				<Col xs="8">
					<h2>Web Development</h2>
				</Col>
				<Col xs="4">
					<img src={logo} width="256" alt="Web Developer concept art" />
				</Col>
			</Row>
		</Container>
	);
}

export default Panel1;