import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import logo from './mobile2.png';

import './PanelEven.css';

function Panel2() {
  return (
	<Container>
		<Row className="PanelEven">
		  <Col xs="4">
			<img src={logo} width="256" alt="Mobile Developer concept art" />
		  </Col>
		  <Col xs="8">
			<h2>Mobile Development</h2>
		  </Col>
		</Row>
		</Container>
  );
}

export default Panel2;