import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import logo from './wordpress.png';

import './Panel.css';

function Panel3() {
	return (
		<Container>
			<Row className="Panel">
				<Col xs="8">
					<h2>Wordpress</h2>
				</Col>
				<Col xs="4">
					<img src={logo} width="256" alt="Wordpress concept art" />
				</Col>
			</Row>
		</Container>
	);
}

export default Panel3;