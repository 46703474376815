import React from 'react';
 
const About = () => {
    return (
       <div>
          <h1>About US</h1>
          <p>About US page body content</p>
       </div>
    );
}
 
export default About;