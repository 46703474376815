import React from 'react';
import { Jumbotron, Container } from 'reactstrap';
import { BrowserRouter, Route, Switch, NavLink } from 'react-router-dom';
 
import Home from './Home.js';
import About from './About';
import Contact from './Contact';
import Error from './Error';

import MainNav from './MainNav.js';

import Footer from './Footer.js';

import './App.css';

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Jumbotron className="Jumbotron">

					<NavLink to="/"><h1>Sourcery Software</h1></NavLink>
					<h2>Your source for great web and mobile apps and all your Wordpress needs.</h2>

					<MainNav />
				    <Switch>
             	<Route path="/" component={Home} exact/>
             	<Route path="/about" component={About}/>
             	<Route path="/contact" component={Contact}/>
            	<Route component={Error}/>
           	</Switch>

				</Jumbotron>

			</BrowserRouter>
			<Footer />
		</div>
	);
}

export default App;
