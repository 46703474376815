import React from 'react';
import { Nav, NavItem, Row, Col, NavLink } from 'reactstrap';
// import { NavLink } from 'react-router-dom';


function MainNav() {
	return (
    <Row className="MainNav">
    	<Col xs="9">
    	</Col>
    	<Col xs="3">
	      <Nav>
	        <NavItem>
	          <NavLink to="/about">About Us</NavLink>
	        </NavItem>
	        <NavItem>
	          <NavLink to="/contact">Contact Us</NavLink>
	        </NavItem>
	      </Nav>
	    </Col>
      <hr />
    </Row>
	);
}

export default MainNav;